@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #fff;
}

@font-face {
  font-family: "Stussy Script";
  src: url("./assets/fonts/StussyScript-Regular.woff2") format("woff2"),
    url("./assets/fonts/StussyScript-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1, h2, h3, button {
  font-family: "Stussy Script";
}

p {
  @apply mb-8;
}

a {
  color: blue;
  text-decoration: underline;
}

.react-css-marquee__text {
  @apply text-2xl;
  
  pointer-events: all !important;
}

.react-css-marquee__wrapper {
  pointer-events: all !important;
  cursor: pointer;
}