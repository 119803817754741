.card {
  /* width: 350px; */
}

@media only screen and (min-width: 640px) {
  .card {
   /* width: 480px; */
  }
}

.close-x {
  stroke: black;
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 5;
}